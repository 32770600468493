import {
  InlineResponse2001,
  OrderItem,
  SapCustomerProduct,
  SapProduct,
} from '@/api/model';
import { OrderApi } from '@/api';
import getConfiguration from '@/store/getConfiguration';
import { SalesOrderAll as SalesOrder } from '@/logic/orders/SalesOrderAll';
import { SapCustomerPartner } from '@/api/model/sap-customer-partner';
import Reorder from '@/logic/orders/Reorder';
import { OrderSuggestion } from '@/types/ApiInterfaces';
import { SapProductForCustomer } from '@/types/SapProductForCustomer';

async function getApi() {
  const configuration = await getConfiguration();
  return new OrderApi(configuration, '');
}

const initialCart = (): SalesOrder => ({
  ReqDlvDate: '',
  ShipToParty: '',
  OrderItems: [] as OrderItem[],
  PurchOrder: '',
});

// Functions for serializing and persisting object with sets
const closedGroupsKey = 'closedGroups';
const getClosedGroups = (): any => {
  try {
    const json = localStorage.getItem(closedGroupsKey);
    if (json) {
      const groups = JSON.parse(json);
      // Convert arrays to sets
      Object.keys(groups).forEach((user) => {
        groups[user] = new Set(groups[user]);
      });
      return groups;
    }
  } catch (e) {
    // Ignore invalid localStorage json
  }
  return {};
};
const setClosedGroups = (groups: any) => {
  const ser: any = {};
  // Convert sets to arrays
  Object.keys(groups).forEach((user) => {
    ser[user] = [...groups[user]];
  });
  localStorage.setItem(closedGroupsKey, JSON.stringify(ser));
};

const initialState = () => ({
  orders: [] as SalesOrder[],
  activeOrders: new Set(), // order ids with details shown
  products: [] as SapProductForCustomer[],
  shipToPartners: [] as SapCustomerPartner[],
  loadingShipToPartners: false,
  orderStep: 'select-products',
  closedGroups: getClosedGroups(),
  cart: { ...initialCart() } as SalesOrder,
  productSearchQuery: '',
  filterGroups: new Set(),
  suggestion: {} as OrderSuggestion,
  suggestionAccepted: false,
  suggestionCancelled: false,
  cartChanged: false,
  availableWarehouses: {} as { [key: string]: string },
  preferredWarehouse: '',
  orderLockedWarehouse: '',
});
export type OrderState = ReturnType<typeof initialState>;

export interface ProductGroup {
  [label: string]: SapProduct[];
}

export default {
  namespaced: true,
  state: initialState(),

  mutations: {
    resetState(state: OrderState) {
      Object.assign(state, initialState());
    },

    setOrders(state: OrderState, orders: SalesOrder[]) {
      state.orders = orders;
    },

    setOrder(state: OrderState, updateOrder: SalesOrder) {
      // Find order
      const index = state.orders.findIndex(
        (order: SalesOrder) => order.Id === updateOrder.Id,
      );
      if (index !== -1) {
        // Replace the order with the detailed info.
        state.orders[index] = updateOrder;
        state.orders = [...state.orders];
      } else {
        // If the order does not exist yet, add it.
        state.orders.push(updateOrder);
      }
    },

    toggleActive(state: OrderState, options: any) {
      const { id, active }: { id: string; active: boolean } = options;
      if (active) {
        state.activeOrders.add(id);
      } else {
        state.activeOrders.delete(id);
      }
      state.activeOrders = new Set(state.activeOrders);
    },

    setProducts(state: OrderState, products: SapProductForCustomer[]) {
      state.products = products;
    },

    setShipToPartners(state: OrderState, partners: SapCustomerPartner[]) {
      state.shipToPartners = partners;
    },

    loadingShipToPartners(state: OrderState, loading: boolean) {
      state.loadingShipToPartners = loading;
    },

    setOrderStep(state: OrderState, step: string) {
      state.orderStep = step;
    },

    setAvailableWarehouses(
      state: OrderState,
      warehouses: { [key: string]: string },
    ) {
      state.availableWarehouses = warehouses;
    },

    toggleActiveGroup(state: OrderState, options: any) {
      const { id, active, user } = options;
      if (!state.closedGroups[user]) {
        state.closedGroups[user] = new Set();
      }

      if (active) {
        state.closedGroups[user].delete(id);
      } else {
        state.closedGroups[user].add(id);
      }

      state.closedGroups[user] = new Set(state.closedGroups[user]);
      state.closedGroups = { ...state.closedGroups };
      setClosedGroups(state.closedGroups);
    },

    openAllProductGroups(state: OrderState, { user }: { user: string }) {
      state.closedGroups[user] = new Set();
      setClosedGroups(state.closedGroups);
    },

    setCartItemQuantity(state: OrderState, options: any) {
      const { product, quantity, unit } = options;
      if (!product.ProductNr || !unit) {
        return;
      }

      const roundedQuantity = quantity ? parseInt(quantity, 10) : 0;

      // Max is 45 for pallets, 750 for layers
      const maximum = unit === 'PAL' ? 45 : 750;
      const validQuantity = Math.min(roundedQuantity, maximum);

      const item: OrderItem = {
        ProductNr: product.ProductNr,
        Quantity: `${validQuantity}`,
        SalesUnit: unit,
        ItemText: product.ProductText,
        ShortCode: product.ShortCode,
      };
      const shouldDelete = roundedQuantity === 0;

      // Check if product already exists in this order items
      if (!state.cart.OrderItems) {
        state.cart.OrderItems = [];
      }
      const index = state.cart.OrderItems.findIndex(
        (i: OrderItem) =>
          i.ProductNr === item.ProductNr && i.SalesUnit === item.SalesUnit,
      );
      if (index !== -1) {
        if (shouldDelete) {
          state.cart.OrderItems.splice(index, 1);
        } else {
          state.cart.OrderItems[index] = item;
        }
      } else if (!shouldDelete) {
        state.cart.OrderItems.push(item);
      }

      state.cart.OrderItems = [...state.cart.OrderItems];
      state.cart = { ...state.cart };

      const productWithWareHouse = state.cart.OrderItems?.find((item) => {
        const product = state.products.find(
          (product) => product.ProductNr === item.ProductNr,
        );

        return product && product.DeliveryPlantCode;
      });

      if (productWithWareHouse) {
        const product = state.products.find(
          (product) => product.ProductNr === productWithWareHouse.ProductNr,
        );

        if (product) {
          state.orderLockedWarehouse = product.DeliveryPlantCode as string;
        }
      } else {
        state.orderLockedWarehouse = '';
      }
    },

    removeCartItems(state: OrderState, items: OrderItem[]) {
      if (state.cart.OrderItems) {
        state.cart.OrderItems = state.cart.OrderItems.filter(
          (i) => !items.includes(i),
        );
      }
    },

    setOrderReference(state: OrderState, reference: string) {
      state.cart.PurchOrder = reference;
      state.cart = { ...state.cart };
    },

    setRequestedDeliveryDate(state: OrderState, date: string) {
      state.cart.ReqDlvDate = date;
      state.cart = { ...state.cart };
    },

    setCartShipToParty(state: OrderState, shipToPartner: SapCustomerPartner) {
      if (shipToPartner.CustomerNr !== undefined) {
        state.cart.ShipToParty = shipToPartner.CustomerNr;
        state.cart = { ...state.cart };
      }
    },

    resetCart(state: OrderState) {
      state.cart = { ...initialCart() };
      state.suggestion = initialState().suggestion;
      state.suggestionAccepted = false;
      state.suggestionCancelled = false;
      state.cartChanged = false;
    },

    resetOrderLockedWarehouse(state: OrderState): void {
      state.orderLockedWarehouse = '';
    },

    setCartFromOrder(state: OrderState, order: SalesOrder) {
      // Make a copy so original order is not affected by order process
      // Clear everything except Items, ShipToParty
      const { OrderItems, ShipToParty } = order;

      state.cart = { OrderItems, ShipToParty };
    },

    setProductSearchQuery(state: OrderState, query: string) {
      const trimmed = query.trim();
      state.productSearchQuery = trimmed.length >= 3 ? trimmed : '';
    },

    toggleFilterGroup(state: OrderState, { group, user }: any) {
      if (!state.filterGroups.has(group)) {
        state.filterGroups.add(group);
        if (!state.closedGroups[user]) {
          state.closedGroups[user] = new Set();
        }
        state.closedGroups[user].delete(group);
        state.closedGroups[user] = new Set(state.closedGroups[user]);
        setClosedGroups(state.closedGroups);
      } else {
        state.filterGroups.delete(group);
      }
      state.filterGroups = new Set(state.filterGroups);
    },

    setSuggestion(state: OrderState, suggestion: InlineResponse2001) {
      state.suggestion = suggestion;
    },

    setSuggestionAccepted(state: OrderState, accepted: boolean) {
      state.suggestionAccepted = accepted;
    },

    setSuggestionCancelled(state: OrderState, cancelled: boolean) {
      state.suggestionCancelled = cancelled;
    },

    setCartChanged(state: OrderState, value: boolean) {
      state.cartChanged = value;
    },

    setPreferredWarehouse(state: OrderState, warehouse: string) {
      state.preferredWarehouse = warehouse;
    },
  },

  actions: {
    async fetchOrders({ commit }: { commit: Function }) {
      const api = await getApi();
      const { data } = await api.ordersGet();
      commit('setOrders', data);
    },

    async setPreferredWarehouseFromSelect(
      { commit }: { commit: Function },
      warehouse: string,
    ) {
      commit('setPreferredWarehouse', warehouse);
    },

    async fetchOrder({ commit }: { commit: Function }, id: string) {
      const api = await getApi();
      const { data } = await api.orderGet(id);
      const order = { ...data, Id: id };
      commit('setOrder', order);
    },

    async postNewOrder({ commit }: { commit: Function }, order: SalesOrder) {
      const postOrder = { ...order };
      // Cleanup order
      if (postOrder.OrderItems) {
        postOrder.OrderItems = postOrder.OrderItems.map(
          ({ ProductNr, Quantity, SalesUnit }) => ({
            ProductNr,
            Quantity,
            SalesUnit,
          }),
        );
      }
      const api = await getApi();
      await api.newOrderPost(postOrder);
      commit('resetCart');
    },

    async fetchProducts({
      commit,
      state,
    }: {
      commit: Function;
      state: OrderState;
    }) {
      // Do not retrieve the products more than once per session
      if (state.products.length === 0) {
        const api = await getApi();
        const { data } = await api.productsGet();

        // To limit the amount of code change for the current upgrade we decided to keep the code as is when possible and just push these two types into one type,
        // this way all logic stays the same and we can just use the new data where it's needed
        commit(
          'setProducts',
          data.map((product) => ({ ...product.sap_product, ...product })),
        );

        // create a list of available warehouses, that can be used for labels and the selection of the preferred warehouse
        commit(
          'setAvailableWarehouses',
          data.reduce(
            (acc: { [key: string]: string }, cur: SapCustomerProduct) => {
              if (
                cur.DeliveryPlantCode != null &&
                !acc[cur.DeliveryPlantCode]
              ) {
                acc[cur.DeliveryPlantCode] = <string>cur.DeliveryPlantCity;
              }
              return acc;
            },
            {},
          ),
        );
      }
    },

    async fetchShipToPartners({
      commit,
      state,
    }: {
      commit: Function;
      state: OrderState;
    }) {
      // Do not retrieve the shipping partners more than once per session
      if (state.shipToPartners.length === 0) {
        try {
          const api = await getApi();
          commit('loadingShipToPartners', true);
          const { data } = await api.shipToPartnersGet();
          commit('setShipToPartners', data);
          commit('loadingShipToPartners', false);
        } catch (err) {
          commit('loadingShipToPartners', false);
          throw err;
        }
      }
    },

    productSearch(
      { commit, rootState }: { commit: Function; rootState: any },
      query: string,
    ) {
      commit('setProductSearchQuery', query);
      if (query) {
        commit('openAllProductGroups', { user: rootState.auth.user.username });
      }
    },

    async reOrder(
      {
        commit,
        dispatch,
        state,
      }: { commit: Function; dispatch: Function; state: OrderState },
      order: SalesOrder,
    ) {
      // Fetch order details if not available yet
      if (!order.OrderItems) {
        await dispatch('fetchOrder', order.Id);
        order = state.orders.find((o) => o.Id === order.Id) || order;
      }

      // Get the products if they're not set already
      if (state.products.length === 0) {
        await dispatch('fetchProducts');
      }

      // An existing order is always in Cartons and has to be converted
      // to Layers and Pallets.
      const newItems = Reorder.convertOrderItemsToCartItems(
        state.products,
        order.OrderItems,
      );

      const newOrder = {
        OrderItems: newItems,
        ShipToParty: order.ShipToParty,
      };

      commit('resetCart');
      commit('setCartFromOrder', newOrder);
    },

    resetSuggestion({ commit }: any) {
      commit('setSuggestionAccepted', false);
      commit('setSuggestionCancelled', false);
      commit('setSuggestion', {});
      commit('setCartChanged', false);
    },
  },

  getters: {
    hasOrders: (state: OrderState): boolean => state.orders.length !== 0,

    preferredWarehouse: (state: OrderState): string => state.preferredWarehouse,

    orderLockedWarehouse: (state: OrderState): string =>
      state.orderLockedWarehouse,

    activePreferredWarehouse: (state: OrderState): string =>
      state.orderLockedWarehouse || state.preferredWarehouse,

    // Sort by created descending
    sortedOrders: (state: OrderState): SalesOrder[] =>
      [...state.orders].sort((a: SalesOrder, b: SalesOrder) => {
        const aCreated = parseInt(`${a.CreatedOn}${a.CreatedAt}`, 10);
        const bCreated = parseInt(`${b.CreatedOn}${b.CreatedAt}`, 10);
        if (!aCreated) {
          return 1;
        }
        if (!bCreated) {
          return -1;
        }
        return aCreated > bCreated ? -1 : 1;
      }),

    lastOrder: (state: OrderState, getters: any): SalesOrder | undefined => {
      if (!getters.hasOrders) {
        return undefined;
      }
      return getters.sortedOrders[0];
    },

    findOrderById:
      (state: OrderState) =>
      (orderId: string): SalesOrder | undefined =>
        state.orders.find((order) => order.Id === orderId),

    productGroups:
      (state: OrderState, getters: any) =>
      (search = false): ProductGroup[] => {
        const sortByIdOrLabel = (a: SapProduct, b: SapProduct) => {
          if (a.Index && a.Label && b.Index && b.Label) {
            if (a.Index === b.Index) {
              return a.Label >= b.Label ? 1 : -1;
            }
            return a.Index > b.Index ? 1 : -1;
          }
          return 0;
        };
        const groupBy = (items: any[], key: string): any =>
          items.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
              currentValue,
            );
            return result;
          }, {});
        const products = search
          ? getters.productSearchResult
          : [...state.products];

        const sortedProducts = products.sort(sortByIdOrLabel);
        return groupBy(sortedProducts, 'Label');
      },

    // Check if the order is active
    isActive:
      (state: OrderState) =>
      (orderId: string): boolean =>
        state.activeOrders.has(orderId),

    // Check if the product group is active
    isActiveGroup:
      (state: OrderState) =>
      ({ group, user }: any): boolean =>
        state.closedGroups[user] ? !state.closedGroups[user].has(group) : true,

    isFilterGroup:
      (state: OrderState) =>
      (groupLabel: string): boolean =>
        state.filterGroups.has(groupLabel),

    getCartItem:
      (state: OrderState) =>
      (product: SapProduct, unit: string): OrderItem | undefined => {
        if (!state.cart.OrderItems) {
          return undefined;
        }

        return state.cart.OrderItems.find(
          (i: OrderItem) =>
            i.ProductNr === product.ProductNr && i.SalesUnit === unit,
        );
      },

    getWarehouseLabel:
      (state: OrderState) =>
      (warehouse: string): string =>
        state.availableWarehouses[warehouse] || '',

    availableWarehouses: (state: OrderState): { [key: string]: string } =>
      state.availableWarehouses,

    warehouseCount: (state: OrderState): number =>
      Object.keys(state.availableWarehouses).length,

    productSearchResult: (state: OrderState): SapProduct[] => {
      if (!state.productSearchQuery) {
        return state.products;
      }
      function escapeRegex(string: string) {
        return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
      }
      const query = new RegExp(escapeRegex(state.productSearchQuery), 'i');
      const fields = [
        'ProductNr',
        'ShortCode',
        'ProductText',
        'HierarchyLabel',
        'CustomProductDesc',
        'CustomProductNr',
      ];
      return state.products.filter((product) => {
        if (product === undefined) {
          return false;
        }
        return fields.some((field) => {
          const p: any = product;
          return p[field] && p[field].search(query) !== -1;
        });
      });
    },

    suggestionAction: (state: OrderState): string => {
      const { Fit: fit, CorrectionSuggestion: correction } = state.suggestion;
      let action = 'next-step';
      if (correction && Object.keys(correction).length) {
        if (fit) {
          action = 'suggest-fill-scale';
        } else {
          action = 'suggest-remove';
        }
      }
      return action;
    },

    productByNr:
      (state: OrderState) =>
      (productNr: string): SapProduct | undefined =>
        state.products.find((product) => product.ProductNr === productNr),
  },
};
